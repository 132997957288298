<template>

    <div class="container-modal" :class="clicked === true ? 'container-modal' : 'container-modal-none' ">
        <div class="sub-container-modal">
            <div class="exit-container">
                <img class="exit"
                     src="../../assets/icons/exit.svg"
                     alt=""
                     v-on:click="getClicked(!clicked)"
                     click="BtnModal"
                >
            </div>
            <div class="header-text">
                <h3>
                    {{ isEdit ? 'Изменить' : 'Добавить' }} категорию.
                </h3>
            </div>


            <form action="" @submit.prevent="onSubmit">
                <div class="fio-row">
                    <p class="fio-text">Название</p>
                    <div class="fioInputContainer">
                        <input class="fio-input" type="text" id="fio"
                               placeholder="Введите название на русском"
                               v-model="name_ru"
                        >

                    </div>

                </div>
                <div class="tel-row">
                    <p class="tel-text">Name</p>
                    <div class="telInputContainer">
                        <input class="tel-input"
                               placeholder="Enter the name in English"
                               v-model="name_eng"
                        >
                    </div>

                </div>
                <div class="comment-row" >
                    <p class="comment-text">Описание</p>
                    <div class="commentInputContainer">
                        <textarea 
                              class="comment-input" type="text" id="comment"
                              placeholder="Введите описание на русском"
                              v-model="description_ru"
                              >
                        </textarea>
                    </div>
                </div>
                <div class="comment-row" >
                    <p class="comment-text">Discription</p>
                    <div class="commentInputContainer">
                        <textarea 
                              class="comment-input" type="text" id="comment"
                              placeholder="Enter a description in English"
                              v-model="description_eng"
                              >
                        </textarea>
                    </div>
                </div>
                <div class="btn-container">
                    <button @click="message" class="btn">Сохранить</button>
                </div>

            </form>
        </div>
    </div>

</template>

<script>

import {HTTP} from '@/plugins/http-common'

const emailCheckRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const fioCheckRegex = /^([А-Яа-я]{3,30} [А-Яа-я]{3,30} [А-Яа-я]{3,30})$/i;

export default {

    name: 'ModalAddAndEditCategory',

    data() {
        return {
            click: true,
            name_ru: '',
            name_eng: '',
            description_ru: '',
            description_eng: '',
        };
    },
    mounted() {
        if (this.editCategory) {
            this.name_ru = this.editCategory.name_ru || '';
            this.name_eng = this.editCategory.name_eng || '';
            this.description_ru = this.editCategory.description_ru || '';
            this.description_eng = this.editCategory.description_eng || '';
        }
    },

    props: {
        getClicked: Function,
        clicked: Boolean,
        isEdit: Boolean,
        editCategory: Object,
        getCategoies: Function
    },


    methods: {

        message() {
            const formData = new FormData();
            formData.append('name_ru', this.name_ru);
            formData.append('name_eng', this.name_eng);
            formData.append('description_ru', this.description_ru);
            formData.append('description_eng', this.description_eng);

            const url = this.isEdit ? `/category/categories/${this.editCategory.id}` : '/category/categories';
            const method = this.isEdit ? HTTP.put : HTTP.post;

            method(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            .then((response) => {
                const action = this.isEdit ? 'Update' : 'Creation';
                console.log(`${action} successful:`, response.data);
                this.$emit('categoryUpdated');
            })
            .catch((error) => {
                console.error(`${this.isEdit ? 'Error updating' : 'Error creating'} category:`, error);
            });
        },
        someAction() {
            if (!this.isEmailValid) {
                return;
            }


        },
    },

    watch: {
        editCategory: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.name_ru = newVal.name_ru || '';
                    this.name_eng = newVal.name_eng || '';
                    this.description_ru = newVal.description_ru || '';
                    this.description_eng = newVal.description_eng || '';
                }
            }
        }
    }


}


</script>

<style scoped>
.container-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.34);
    
}

.container-modal-none {
    display: none;
}

.sub-container-modal {
    display: flex;
    max-width: 700px;
    padding-top: 20px;
    align-items: center;
    /*justify-content: center;*/
    flex-direction: column;
    width: 100%;
    color: var(--text);

    background: var(--modal-bg);
    border: 2px solid #C9C9C9;
    padding-bottom: 50px;
    border-radius: 15px;
    position: absolute;
}

.header-container {
    width: 100%;
    display: flex;
    justify-content: center;

}


form {
    display: flex;
    flex-direction: column;
    width: 80%;
    row-gap: 20px;
}

.header-text {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding-bottom: 40px;
}

h2 {
    text-transform: uppercase;
}

.fio-row {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.fioInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    
}

.fio-text {
    padding-right: 20px;
    
}


.fio-input {
    width: 100%;

}

input {
    background-color: #ecf0f1;
    border-radius: 15px;
    width: 100%;
    padding-left: 15px;
    border: none;
    height: 26px;
    box-sizing: border-box;
}

input:focus {
    border: 2px solid #C9C9C9;
    outline: 0;
    background-color: #FFF;
}

.tel-row {

    display: flex;
    flex-direction: row;
align-items: center;
    justify-content: space-between;

}

.telInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}


.tel-text {
    width: 21%;
}


.tel-input {
    width: 100%;
}


.email-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


}

.emailInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}


.email-input {
    width: 100%;

}

.comment-input{
  width: 100%;
  height: 10vh;
  background-color: #ecf0f1;
  border-radius: 15px;
  padding-left: 1vw;
  border: none;
  box-sizing: border-box;
  padding-top: 1vh;
  margin-top: 1vh;
  resize: none;
}

textarea:focus {
    border: 2px solid #C9C9C9;
    outline: 0;
    background-color: #FFF;
}

.emailErr {
    border: 2px solid #fd0000;
}

.error-input {
    padding-top: 5px;
    font-size: 12px;
    line-height: 120%;
    color: #FF0000;
    padding-left: 5px;
    display: none;
}

.errorInputOn {
    display: flex;
}




.message-title {
    border: 2px solid #C9C9C9;
    border-radius: 15px;
    padding: 5px;
}


.message-input:focus {
    border: 2px solid #C9C9C9;
    outline: 0;
}

.btn-container {
    display: flex;
    justify-content: center;
}

.message-text{
  padding-bottom: 1vh;
}

.btn {
    width: 200px;
    height: 40px;
    border: 2px solid #1D71B8;

    background-color: #FFF;
    border-radius: 15px;
    margin-top: 20px;
    color: #1E70B6;
    font-size: 20px;
    transition: 0.3s;
}

.btn:hover {
    letter-spacing: 2px;

}

.exit-container {
    width: 100%;

    display: flex;
    filter: invert(var(--invert));
    justify-content: end;
    align-items: flex-start;


}

.exit {
    padding-right: 15px;

    cursor: pointer;
    height: 20px;
    width: 20px;
}

@media (max-width: 740px) {
    .sub-container-modal {
        width: 90%;
        /*height: 500px;*/
        justify-content: baseline;
    }

    .exit-container {


        padding-bottom: 10px;
    }

    .btn-container {
        padding-bottom: 2px;
        padding-top: -2px;
    }

    .header-text {
        row-gap: 10px;
    }

    h2, h3, .tel-text, .email-text, .zakaz, .btn-container, .exit-container {
        overflow: hidden;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
        overflow: hidden;
    }

    .btn {
        height: 40px;
    }

}

@media (max-width: 320px) {
    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
    }
}
</style>
