<template>
    <div class="container">
        <div class="sub-container">
            <div class="left-side">
                <h2>
                    {{$t('ReadySystems.ready')}}
                </h2>
                <p>
                    {{$t('ReadySystems.description')}}
                </p>
                <CustomButton 
                :buttonText="$t('Buttons.more')"
                :link="'/catalog/software'"
                />
            </div>
            <div class="right-side">
                <ImageStack/>
            </div>
        </div>
    </div>
</template>

<script>
    import ImageStack from '@/components/ImageStack.vue';

    export default {
        components: {
            ImageStack
        },

        data(){
            return {
                pickedIndex: 0,
            }
        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        scroll-snap-align: center;

    }

    .sub-container {
        width: 1300px;
        

        padding-top: 40px;
        
        display: flex;
        justify-content: space-between;
    }

    .left-side {
        min-width: 320px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        
    }

    .right-side {
        width: 50%;
        height: 370px;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }

    span {
        color: var(--text);
        margin-top: 48px;
        margin-bottom: 48px;
        font-size: 22px;
    }

    h2 {
        font-size: 52px;
        color: var(--text);
        
        font-family: geometria_extralight;

        text-transform: uppercase;
    }

    h3 {
        font-size: 26px;
        font-weight: 100;
        color: var(--text);
        text-transform: uppercase;
    }

    @media (max-width: 1300px){
        .left-side{
            text-align: center;
            align-items: center;
            
           
        }

        .right-side{
            min-width: 50%;
            
        }

        

        span{
            width: 90%;
            
        }
    }

    @media (max-width: 1000px){
        .right-side{
            width: 60%;
            
        }
    }
    
    @media (max-width: 800px){
        .sub-container{
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center
        }

        h2{
            font-size: 33px;
        }

        .left-side{
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-bottom: 50px;
            max-width: 80%;
            
        }

        .right-side{
            
            width: 80%;
            align-items: center;
            justify-content: center;
        }
    }

    @media (max-width: 500px){
        .right-side{
            
            height: 260px;
            
        }
    }

</style>