<template>

    <div class="container-modal" :class="clicked === true ? 'container-modal' : 'container-modal-none' ">
        <div class="sub-container-modal">
            <div class="exit-container">
                <img class="exit"
                     src="../../assets/icons/exit.svg"
                     alt=""
                     v-on:click="getClicked(!clicked)"
                     click="BtnModal"
                >
            </div>
            <div class="header-text">
                <h2 v-show="vacancy === false">
                    Для покупки заполните и отправьте заявку
                </h2>
                <h2 v-show="vacancy === true">
                    Оставьте свои контакты
                </h2>
                <h3>
                    Мы свяжемся с вами в ближайшее время.
                </h3>
            </div>


            <form action="" @submit.prevent="onSubmit">
                <div class="fio-row">
                    <p class="fio-text">фио</p>
                    <div class="fioInputContainer">
                        <input class="fio-input" type="text" id="fio"
                               placeholder="Иванов Иван Иванович"
                               v-model="fio"
                               :class="{ emailErr: isFioError && this.fio.length > 0}"
                        >
                        <p class="error-input" :class="{ errorInputOn: isFioError && this.fio.length > 0} ">Введите
                            полное ФИО</p>
                    </div>

                </div>
                <div class="tel-row">
                    <p class="tel-text">Ваш номер телефона</p>
                    <div class="telInputContainer">
                        <input ref="el" class="tel-input"
                               v-on:input="tel = $event.target.value" placeholder="Номер телефона"
                               :class="{ emailErr: this.tel.length < 18  && this.tel.length > 3} "
                        >
                        <p class="error-input" :class="{ errorInputOn: this.tel.length < 18  && this.tel.length > 3} ">
                            Введите корректый номер телефона</p>
                    </div>

                </div>

                <div class="email-row">
                    <p class="email-text">Ваш email адрес</p>
                    <div class="emailInputContainer">
                        <input class="email-input"
                               :class="{ emailErr: isEmailError && this.email.length > 0}" type="email"
                               placeholder="email"
                               v-model="email"
                               @blur="isEmailTouched = true"
                        >
                        <p class="error-input" :class="{ errorInputOn: isEmailError && this.email.length > 0} ">Введите
                            корректый email адрес</p>
                    </div>

                </div>
                <div class="comment-row" 
                  v-show="vacancy === false"
                >
                    <p class="comment-text">Комментарий</p>
                    <div class="commentInputContainer">

                        <textarea 
                              class="comment-input" type="text" id="comment"
                              placeholder="Введите комментарий"
                              v-model="comment"
                              
                              >

                        </textarea>

                    </div>

                </div>
                <div class="zakaz">
                    <p class="message-text"
                       v-show="vacancy === false"
                    >
                      Заказать товар
                    </p>
                    <p class="message-text"
                       v-show="vacancy === true"
                    >
                      Вакансия
                    </p>
                    <p class="message-title">{{ title }}</p>
                </div>
                <div class="btn-container">
                    <button @click="message" v-on:click="getClicked(!clicked)" class="btn">Отправить</button>
                </div>

            </form>
        </div>
    </div>

</template>

<script>

import {useIMask} from 'vue-imask';
import {HTTP} from '@/plugins/http-common'

const emailCheckRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const fioCheckRegex = /^([А-Яа-я]{3,30} [А-Яа-я]{3,30} [А-Яа-я]{3,30})$/i;

export default {

    name: 'Modal',

    data() {
        return {
            click: true,
            fio: '',
            tel: '',
            email: '',
            comment: '',
            isEmailTouched: false,

        }
    },

    setup(props) {
        const {el, masked} = useIMask({
            mask: '+{7} (000) 000 00 00',
            radix: '.',
        });

        return {el};
    },
    props: {
        getClicked: Function,
        clicked: Boolean,
        vacancy: {
          default: false
        },
        title: {
            default: ''
        },
    },

    computed: {
        isEmailValid() {
            return emailCheckRegex.test(this.email);
        },

        isEmailError() {
            return !this.isEmailValid && this.isEmailTouched;

        },

        isFioValid() {
            return fioCheckRegex.test(this.fio);
        },

        isFioError() {
            return !this.isFioValid;

        },

    },
    updated() {
        // console.log(this.title)

    },

    methods: {
        message() {
            if (this.isEmailValid && this.isFioValid) {
                // console.log("Сообщение отправлено", this.fio, this.el.value, this.email, this.title);
                // this.$metrika.reachGoal('forma');
                HTTP.post(`/feedback/send_message/${this.fio}/${this.el.value}/${this.email}/${this.title}/${this.comment}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (response) {
                        this.$metrika.reachGoal('forma')
                        // console.log("Сообщение отправлено", response.data);


                    }).catch(() => {
                    this.wrongData = true;
                })

                this.fio = ''
                this.el.value = ''
                this.email = ''
                this.comment = ''

            } else {
                alert('Введите корректные данные');
            }

        },
        someAction() {
            if (!this.isEmailValid) {
                return;
            }


        },
    },


}


</script>

<style scoped>
.container-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.34);
    
}

.container-modal-none {
    display: none;
}

.sub-container-modal {
    display: flex;
    max-width: 700px;
    padding-top: 20px;
    align-items: center;
    /*justify-content: center;*/
    flex-direction: column;
    width: 100%;
    color: var(--text);

    background: var(--modal-bg);
    border: 2px solid #C9C9C9;
    padding-bottom: 50px;
    border-radius: 15px;
    position: absolute;
}

.header-container {
    width: 100%;
    display: flex;
    justify-content: center;

}


form {
    display: flex;
    flex-direction: column;
    width: 80%;
    row-gap: 20px;
}

.header-text {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding-bottom: 40px;
}

h2 {
    text-transform: uppercase;
}

.fio-row {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.fioInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    
}

.fio-text {
    text-transform: uppercase;
    padding-right: 20px;
    
}


.fio-input {
    width: 100%;

}

input {
    background-color: #ecf0f1;
    border-radius: 15px;
    width: 100%;
    padding-left: 15px;
    border: none;
    height: 26px;
    box-sizing: border-box;
}

input:focus {
    border: 2px solid #C9C9C9;
    outline: 0;
    background-color: #FFF;
}

.tel-row {

    display: flex;
    flex-direction: row;
align-items: center;
    justify-content: space-between;

}

.telInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}


.tel-text {
    width: 50%;
}


.tel-input {
    width: 100%;
}


.email-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


}

.emailInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}


.email-input {
    width: 100%;

}

.comment-input{
  width: 100%;
  height: 10vh;
  background-color: #ecf0f1;
  border-radius: 15px;
  padding-left: 1vw;
  border: none;
  box-sizing: border-box;
  padding-top: 1vh;
  margin-top: 1vh;
  resize: none;
}

textarea:focus {
    border: 2px solid #C9C9C9;
    outline: 0;
    background-color: #FFF;
}

.emailErr {
    border: 2px solid #fd0000;
}

.error-input {
    padding-top: 5px;
    font-size: 12px;
    line-height: 120%;
    color: #FF0000;
    padding-left: 5px;
    display: none;
}

.errorInputOn {
    display: flex;
}




.message-title {
    border: 2px solid #C9C9C9;
    border-radius: 15px;
    padding: 5px;
}


.message-input:focus {
    border: 2px solid #C9C9C9;
    outline: 0;
}

.btn-container {
    display: flex;
    justify-content: center;
}

.message-text{
  padding-bottom: 1vh;
}

.btn {
    width: 200px;
    height: 40px;
    border: 2px solid #1D71B8;

    background-color: #FFF;
    border-radius: 15px;
    margin-top: 20px;
    color: #1E70B6;
    font-size: 20px;
    transition: 0.3s;
}

.btn:hover {
    letter-spacing: 2px;

}

.exit-container {
    width: 100%;

    display: flex;
    filter: invert(var(--invert));
    justify-content: end;
    align-items: flex-start;


}

.exit {
    padding-right: 15px;

    cursor: pointer;
    height: 20px;
    width: 20px;
}

@media (max-width: 740px) {
    .sub-container-modal {
        width: 90%;
        /*height: 500px;*/
        justify-content: baseline;
    }

    .exit-container {


        padding-bottom: 10px;
    }

    .btn-container {
        padding-bottom: 2px;
        padding-top: -2px;
    }

    .header-text {
        row-gap: 10px;
    }

    h2, h3, .tel-text, .email-text, .zakaz, .btn-container, .exit-container {
        overflow: hidden;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
        overflow: hidden;
    }

    .btn {
        height: 40px;
    }

}

@media (max-width: 320px) {
    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
    }
}
</style>
