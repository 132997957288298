<script setup>
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n({useScope: 'global'});
</script>

<template>
    <div class="container" @mousemove="moveBackground($event)">
      <div class="decorate-circles"></div>

      <div class="sub-container">
        <!--   Image    -->
        <div class="main-image-container">
          <div
              class="decorate-gradient"
              v-bind:style="{ marginTop: backgroundX*5 + 'px',
                            marginLeft: backgroundY*5 + 'px' }">
          </div>
          <div class="black-gradient"></div>
          <div
              :style="{backgroundImage: `url(${require('../assets/backgrounds/' + backgrounds[backgroundNumber])})`}"
              class="slider-image">
          </div>
        </div>

        <!--   Text    -->
          <div class="text-container">
            <div class="h1-container">
              <h1>{{ title }}</h1>
              <h2 class="span_text">{{ description}}</h2>
            </div>
          </div>


      </div>

      <div class="dots" @mouseenter="autoSkip = false" @mouseleave="autoSkip = true">
            <div 
                class="dot" 
                v-for="(background, index) in backgrounds"
                :key="index"
                @click="backgroundNumber = index"
                :class="{'picked-dot':index == backgroundNumber}">
            </div>
      </div>
    </div>
</template>

<script>
export default {
    name:'FullscreenSlider',
    data(){
        return {
            movewait: undefined,
            backgroundX: 0,
            backgroundY: 0,
            autoSkip: true,
            backgroundNumber: 0,
            backgrounds: ['microBackground1.webp',
                'microBackground2.webp',
                'microBackground3.webp',
                'microBackground4.webp'],
            
            texts_ru: ['Автоматика',
                    'Передача данных',
                    'Видеонаблюдение',
                    'Программное обеспечение'],

            texts_en: ['Automation',
                    'Data transfer',
                    'Video monitoring',
                    'Software']
        }
    },

    props: {
        title: String,
        description: String
    },

    methods: {
        moveBackground(e){

            // this.backgroundX = (window.innerWidth/2 - e.clientX)/50;
            // this.backgroundY = (window.innerHeight/2 - e.clientY)/50;

            if(typeof this.movewait != 'undefined'){
                    clearTimeout(this.movewait);
                }

            this.movewait = setTimeout(function(){
                // this.backgroundX = (window.innerWidth/2 - e.clientX)/50;
                // this.backgroundY = (window.innerHeight/2 - e.clientY)/50;
                this.changeMousePosition((window.innerWidth/2 - e.clientX)/30, (window.innerHeight/2 - e.clientY)/30)
                // console.log();
            }.bind(this),50);

            // console.log(e);
        },

        changeMousePosition(x, y){
            this.backgroundX = x;
            this.backgroundY = y;
        },

        changeBackground(backgroundNumber){

        }
    },

    mounted() {
        setInterval(() => {
            if(this.autoSkip){
                this.backgroundNumber++   
                if(this.backgroundNumber >= this.backgrounds.length){
                    this.backgroundNumber = 0;
                }
            }
        }, 3500)
    },

    destroyed() {

    }
    
}
</script>

<style scoped>
    .container  {
        width: 100%;
        height: 70vh;
        
        position: relative;
        
        overflow: hidden;
        background-color: #040405;
        align-items:center;
        display: flex;
        flex-direction: column;

    }

    .sub-container {
      display: flex;
      flex-direction: row-reverse;
      
      /*Обязательно убрать width*/
      width: 100%;
      max-width: 1300px;
      height: 100%;
    }

    .black-gradient {
        width: 100vw;
        height: 100%;

        position: absolute;

        background: radial-gradient(circle, rgba(255,255,255,0) 20%, rgba(2,2,2,1) 100%);
        background-position-x: 200px;

        pointer-events: none;

        z-index: 2;
    }

    .span_text::first-letter {
        text-transform: uppercase;
    }   

    

    @keyframes image-smoother {
        0% {
            transform: translateX(50px) scale(1.1);
            opacity: 0;
        }

        100% {
            transform: translateX(0px) scale(1.1);
            opacity: 1;
        }
    }
    .slider-image {
        width: 100vw;
        height: 100vh;
        animation: image-smoother 1s ease-in-out;
        background-image: url(@/assets/backgrounds/background1.webp);

        background-position-x: center;
        background-repeat: no-repeat;
        background-size: cover;

        /*transform: scale(1.1);*/
        pointer-events: none;

        position: absolute;

        transition: 0.5s;
        background-attachment: fixed;

    }

    .slider-text-container {
        color: white;

        z-index: 7;

        display: flex;
        flex-direction: column;

        padding-left: 2%;
        /*width: 600px;*/
        /*height: 300px;*/

        top: 55%;
        width: 100%;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent; 
        align-items: center;

    }

    .slider-text {
        width: 100%;
        font-size: 20px;
        color: #6b6b6b;

        background: none;
        border: none;
        outline: none;
        text-align: left;

        transition: 0.5s;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-tap-highlight-color: transparent; 
    }

    .slider-text:hover {
        color: #ccc;
    }

    .picked-slider-text {
        width: 100%;
        font-size: 30px;
        color: #ffffff;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 10px;
        border-left: solid 4px white;
    }

    .decorate-gradient {
        width: 500px;
        height: 500px;

        position: absolute;
        z-index: 3;

        /* border: solid 1px red; */
        top: 40%;
        left: -10%;

        /* background: radial-gradient(circle, rgba(29,113,184,0.4) 0%, rgba(0,212,255,0) 80%); */
        background-color: rgba(29,113,184,0.4);
        filter: blur(100px);

        border-radius: 100%;

        background-position: center;
        background-repeat: no-repeat;

        transition: 1s;
    }

    @keyframes left-opacity {
        0% {
            transform: translateX(-400px);
            opacity: 0;
        }

        100% {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    

    .h1-container {
        width: 100%;
        padding-top: 10%;

        /*height: 100px;*/

        display: flex;
        flex-direction: column;
        padding-left: 2%;
        /* border: solid 1px blue; */
        /* padding-left: 96px; */
        overflow: visible;
    }
    .text-container {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        /*line-height: 1.5;*/

        overflow: hidden;

        /*pointer-events: none;*/
        flex-direction: column;
        z-index: 3;
    }

    h1 {
        text-transform: uppercase;
        opacity: 0;
        animation: left-opacity 0.8s ease-in-out forwards;

        font-size: clamp(24px, 2.6vw, 48px);
        color: white;
        /*line-height: .88;*/

        overflow: hidden;   
        /* position: absolute; */
    }

    span, h2 {
        font-size: 20px;
        color: rgb(255, 255, 255, 0.8);
        animation: left-opacity 0.8s ease-in-out forwards 0.4s;
        padding: 15px 0;
        opacity: 0;

    }


    .main-image-container {
      width: 50%;
    }

    .dots {
      /*position: absolute;*/
      /*bottom: 32px;*/

      width: 62px;
      height: 14px;

      display: flex;
      justify-content: space-around;
      align-items: center;
        overflow: hidden;
      /*left: calc(50% - 31px);*/
      z-index: 3;
      margin: 0 auto;
      padding: 30px 0;
      /* pointer-events: none; */
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
-webkit-tap-highlight-color: transparent; 
    }

    .picked-dot{
        width: 14px !important;
        height: 14px !important;
    }
    .dot {
        width: 8px;
        height: 8px;

        border-radius: 100%;

        background-color: white;
        cursor: pointer;

        transition: 0.5s;
    }

    @keyframes top-down-opacity {
        0% {
            transform: translateX(-300px) rotate(-90deg);
            opacity: 0;
        }

        100% {
            transform: translateX(0px) rotate(-90deg);
            opacity: 1;
        }
    }

    .decorate-circles {
      animation: top-down-opacity .8s ease-in-out forwards;

      opacity: 0;
      left: 0;

      width: 500px;
      height: 500px;
      background-repeat: no-repeat;

      position: absolute;
      bottom: 0;

      background-image: url(@/assets/backgrounds/decorate_circles.svg);
      transform: rotate(-90deg);

      z-index: 3;
    }

    @media (max-width: 1300px){

        
        .h1-container {
            display: flex;
            /*justify-content: center;*/
            flex-direction: column;
            
            
            margin-top: 40px;
        }

        .slider-image {
            background-position-x: 50% !important;
        }

        h1 {
            /* font-size: 7vh !important; */
            
        }

        .text-container{
            padding-left: 50px;
        }

    }

    @media (max-width: 900px){


        .main-image-container{
            
            width: 100%;
            position: absolute;
        
        }
        .slider-image{
            width: 100%;
            position: relative;
        }

        .slider-text-container{
            margin-top: 40px;
        }

        .slider-text{
            text-align: center;
        }

        .text-container{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0px;
            
        }

        .h1-container{
            display: flex;
            text-align: center;
            width: 70%;
        }

        .picked-slider-text{
            border: none;
            font-size: 26px;
        }



    }

    @media (max-width: 550px){
        .h1-container{
            width: 80%;
            
        }

        .dots{
            height: 20px;
            display: none;
        }

        .slider-text-container{
            margin-top: 20px;
        }
    }

    @media (max-width: 400px){



        .slider-text{
            font-size: 16px;
        }
        .picked-slider-text{
            font-size: 20px;
        }

        .dots{
            height: 15px;
        }

        .slider-text-container{
            margin-top: 0px;
        }

        
    }

    @media (max-height: 665px){
        h2{
            font-size: 22px;
        }

        .slider-text{
            font-size: 16px;
        }
    }
</style>
