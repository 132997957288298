<template>
    <div class="container">
        <h2>
          {{$t('NewsPreview.title')}}
        </h2>
        <div class="sub-container">
            <div class="special-news">

            </div>
            <div class="other-news"
                v-motion
                :initial="{
                    y: 100,
                    opacity: 0,
                }"
                :visible="{
                    y: 0,
                    opacity: 1,
                    transition: {
                        delay: 0,
                        duration: 200
                    }
                }"
            >

                <SceletonMicroNews v-if="images.length == 0"/> 
                
                <MicroNews 
                    v-else-if="$store.state.lang == 'ru-RU'"
                    v-for="(title, index) in microNewsTitlesRU"
                    :FirstPar="title"
                    :id="newsIDRu[index]"
                    :Base64="images[index]"
                />  

                

                <MicroNews 
                    v-else
                    v-for="(title, index) in microNewsTitlesEN"
                    :FirstPar="title"
                    :id="newsIDEn[index]"
                    :Base64="images[index]"
                /> 

                

                
            </div>
        </div>
    </div>
</template>

<script>
    import MicroNews from "@/components/MicroNews.vue"
    import SceletonMicroNews from "@/components/Sceletons/SceletonMicroNews.vue";
    import { HTTP } from '@/plugins/http-common'
// import store from "@/store"

    export default {
        components: {
            MicroNews,
            SceletonMicroNews
           
        },

        data(){
            return {
                specialTitleRU: '',
                specialTitleEN: '',
                specialImage: '',
                newsIDRu: [],
                newsIDEn: [],

                microNewsTitlesRU: [],
                microNewsTitlesEN: [],
                images: [],
            }
        },

        created(){

            // HTTP.get(`/news/?limit=4&skip=0`).then(response => {
            //     // console.log(response.data);
            //
            //     this.specialTitleRU = JSON.parse(response.data[0].content_news_ru).title;
            //     this.specialTitleEN = JSON.parse(response.data[0].content_news_eng).title;
            //     this.specialImage =   response.data[0].image;
            //
            //     for(let i in response.data){
            //         this.microNewsTitlesRU[i-1] = JSON.parse(response.data[i].content_news_ru).title;
            //         this.microNewsTitlesEN[i-1] = JSON.parse(response.data[i].content_news_eng).title;
            //
            //         this.newsIDRu[i-1]=response.data[i].id;
            //
            //         this.newsIDEn[i-1]=response.data[i].id;
            //
            //         this.images[i-1] = response.data[i].image;
            //     }
            //
            //     // console.log(this.microNewsTitlesRU)
            //     // console.log(this.microNewsTitlesEN)
            //     // console.log(this.images)
            //
            //
            // }).catch(err => {
            //     // console.log('Ошибка в запросе', err);
            // })

            // axios.get('/')
            // .then(responce => {
            //     console.log(responce)  
            // })
        }
    }
</script>

<style scoped>
    .container {
      width: 100%;
      flex-direction: column;
      gap: 10px;
      background-color: var(--about-bg);
      padding-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      /*padding-bottom: 50px;*/
      scroll-snap-align: center;
    }
    h2 {
      font-size: 52px;
      color: var(--text);

      font-family: geometria_extralight;

      text-transform: uppercase;
    }
    .sub-container {

        overflow: hidden;

        width: 1300px;
        height: 100%;

        display: flex;
        flex-direction: column;

    }

    .special-news {
        width: 100%;
        height: 500px;
        
        box-sizing: border-box;
        border-radius: 12px;
        position: relative;

        background-position: center;
        
        background: #eee;
        background: var(--sceleton-card);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
        
        
        box-shadow: 1px 2px 12px rgb(0,0,0,0.3);

        overflow: hidden;
    }
    
    .bluredImage {
        width: 100%;
        height: 100%;

        position: absolute;
        background-position: center;
        background-size: cover;

        filter: blur(10px);
    }

    .normalImage {
        width: 100%;
        height: 100%;

        position: absolute;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .special-news:hover .special-news-info{
        height: 100%;
        cursor: pointer;
    }

    .special-news-info {
        width: 100%;
        height: 50%;
        position: absolute;
        
        background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%,rgba(255,255,255,0)  100%);
        bottom: 0;

        display: flex;
        padding-left: 24px;

        transition: 0.5s;
        
    }

    .special-new-title {
        font-size: 35px;
        color: white;
        font-family: geometria_bold;
        width: 70%;

        position: absolute;
        bottom: 44px;
    }

    .special-new-date {
        font-size: 26px;
        color: white;

        position: absolute;
        right: 48px;
        bottom: 24px;
    }

    .other-news {
        width: 100%;
        padding-bottom: 50px;
        box-sizing: border-box;
        
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        padding-top: 24px;
        overflow: hidden;
    }

    @keyframes shine {
  to {
    background-position-x: -200%;
  }
}


    @media (max-width: 1300px){
        .other-news{
            width: 95%;
           
        }

        .special-news{
            width: 95%;
        }

        .sub-container{
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }

    @media (max-width: 800px){
        .other-news{
            flex-wrap: nowrap;
            flex-direction: column;
            
            
        }

        .special-news{
            height: 400px;
        }

        .special-new-title {
            font-size: 24px;
            padding-bottom: 24px;
            width: 85%;
        
        }

        .special-new-date {
            font-size: 18px;
        
        }
    }
</style> 
