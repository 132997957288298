<template>
  
  <li  class="container"
      :to=" isClicked === false ? '' : `/product/${id}`"
      :style="isClicked === false ? {cursor: `default`} : {cursor: `pointer`}"
  >
      <div class="delete-button-container" v-if="user == 'admin'"> 
        <router-link :to="`/editProduct/${id}`">
          <button class="delete-button"> 
          </button>
        </router-link>

        <button class="delete-button" @click="this.isDeleteModal = true"> 
        </button>
      </div>

      <div class="image-container">
          <div class="blurImage" :style="{backgroundImage: `url(${cardImage})`}"></div>
          <div class="normalImage" :style="{backgroundImage: `url(${cardImage})`}"></div>
      </div>
      <p class="text-container">
          {{ cardText }}
      </p>
      
        <CustomButton class="buttom"
          :link="`/catalog/${category_type.replaceAll(' ', '_')}/${cardName.replaceAll(' ', '_')}?id=` + id"
          :buttonText="$t('Buttons.more')"
        />
    </li>
    <div v-show="isDeleteModal">
    <ModalCofirm 
            :title="`Вы уверены, что хотите удалить ${cardText}?`"
            @getClickedClose="isDeleteModal = false"
            @getClickedConfirm="deletePost(id)"
    />
  </div>

</template>

<script>

import {HTTP} from "@/plugins/http-common";
import ModalCofirm from './ModalCofirm.vue'


export default {
name: "FiltCard",
components: {
  ModalCofirm
},
data() {
  return {
    isDeleteModal: false,

  }
},

methods: {
  deletePost(id){
    HTTP.delete('/catalog/delete_catalog/' + id,
    {headers: {
        'Content-Type': 'application/json'
    }})
    .then(function (response) {
      location.reload()
      this.isDeleteModal = false
    }).catch(() => {
        this.wrongData = true;
    })
  }
},

props: {
        cardText: {
            type: String,
            default: "Default card text",
        },

        cardName: {
            type: String,
            default: "Default card text",
        },
        cardImage: {

            default: "no-image",
        },
        isClicked: {
            type: Boolean,
            default: false
        },

        index: {
            default: 0,
        },

        viewIndex: {
            default: 0,
        },
        id: {
            default: 0
        },
        cards: {
            default: [],
        },
        user: {
          default: 'user',
        },
        category_type: {
          type: String,
          default: ''
        }
    },

} 

</script>

<style scoped>


.container {
width: 280px;
height: 426px;

display: flex;
flex-direction: column;
align-items: center;
margin: 20px;
justify-content: space-between;
background: var(--card-bg);

border-radius: 12px;

border-style: solid;
border-width: 1px;
border-image: 
  linear-gradient(
  to bottom, 
  rgba(20, 20, 20, 0.1),
  #ccc, 
  rgba(20, 20, 20, 0.1)
  ) 1 100%;

box-shadow: var(--card-box-shadow);


padding-top: 0px;
padding-bottom: 24px;
position: relative;
}



.image-container {
width: 100%;
height: 280px;

overflow: hidden;

position: relative;

background-size: contain;
background-repeat: no-repeat;
background-position: center;

overflow: hidden;

display: flex;
justify-content: center;
align-items: center;
}

.blurImage {
width: 100%;
height: 100%;

background-size: cover;
background-repeat: no-repeat;
background-position: center;

/* opacity: 0.2; */
position: absolute;
filter: blur(20px);
opacity: 0.6;
}

.normalImage {
width: calc(100% - 15px);
height: calc(100% - 15px);

background-size: contain;
background-repeat: no-repeat;
background-position: center;
position: absolute;

z-index: 2;
}

.text-container {



width: 85%;
bottom: 0px;

padding-top: 10px;
display: flex;
justify-content: center;
align-items: center;

text-align: center;

color: var(--text);

font-size: 16px;
}

.delete-button-container {
position: absolute;
top: 0px;
right: 0px;
z-index: 4 !important;
display: flex;

width: 100%;
justify-content: space-between;
padding: 5px;
box-sizing: border-box;
}

.delete-button {
width: 32px;
height: 32px;

cursor: pointer;

background: white;
font-size: 24px;
font-family: monospace;

border-radius: 5px;
border: solid 1px #ccc;

}

.delete-button:nth-child(1){
background-image: url('./assets/pencil.png');
background-size: 20px;
background-position: center;
background-repeat: no-repeat;
}

.delete-button:nth-child(2){
background-image: url('./assets/delete.png');
background-size: 22px;
background-position: center;
background-repeat: no-repeat;
}

</style>
