import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../pages/HomePage.vue'
import AboutPage from '../pages/AboutPage.vue'
import CatalogPage from '../pages/CatalogPage.vue'
import ContactsPage from "@/pages/ContactsPage.vue";
import NewsPage from "../pages/NewsPage.vue";
import AdminLogin from "../pages/AdminLogin.vue";
import News from "@/pages/AdditionalPages/News.vue";
import IndustrialSafety from "@/pages/AdditionalPages/IndustrialSafety.vue";
import NotFound from "@/pages/NotFound.vue";
import Documentation from "@/pages/AdditionalPages/Documentation.vue";
import OurTeam from "@/pages/AdditionalPages/OurTeam.vue";
import Certificate from "@/pages/AdditionalPages/Certificate.vue";
import Product from "@/pages/AdditionalPages/Product.vue";
import ProductCreator from "@/pages/AdminPages/ProductCreator.vue"
import ProductEditor from "@/pages/AdminPages/ProductEditor.vue"
import NewsCreator from "@/pages/AdminPages/NewsCreator.vue"
import NewsEditor from "@/pages/AdminPages/NewsEditor.vue"
// import WorkerCreator from "@/pages/AdminPages/WorkerCreator.vue"
import IndustrialSafetyPage from '@/pages/IndustrialSafetyPage.vue'
import VacancyPage from "@/pages/VacancyPage.vue";
import Vacancy from "@/pages/AdditionalPages/Vacancy.vue";
import VacancyCreator from "@/pages/AdminPages/VacancyCreator.vue";
import VacancyEditor from "@/pages/AdminPages/VacancyEditor.vue";


import SkeletonAdditionalNews from "@/components/Sceletons/SkeletonAdditionalNews.vue";


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage
  },
  {
    path: '/safety',
    name: 'safety',
    component: IndustrialSafetyPage
  },
  {
    path: '/catalog',
    name: 'catalog',
    props: true,
    component: CatalogPage
  },
  {
    path: '/catalog/:category',
    name: 'catalog_categoty',
    component: CatalogPage
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsPage
  },
  {
    path: '/news',
    name: 'news',
    component: NewsPage
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminLogin
  },
  {
    path: '/news/:newsName',
    name: 'newsId',
    component: News,
    props: (route) => ({  newsName: route.params.newsName, id: route.query.id })
  },
  // {
  //   path: '/industrialSafety',
  //   name: 'industrialSafety',
  //   component: IndustrialSafety
  // },
  // {
  //   path: '/documentation',
  //   name: 'Documentation',
  //   component: Documentation
  // },
  {
    path: '/team',
    name: 'Team',
    component: OurTeam
  },
  // {
  //   path: '/certificate',
  //   name: 'Certificate',
  //   component: Certificate
  // },
  {
    path: '/catalog/:category/:productName',
    name: 'Product',
    component: Product,
    props: (route) => ({ category: route.params.category, productName: route.params.productName, productID: route.query.id })
  },
  {
    path: '/404',
    name: 'Not found',
    component: NotFound
  },
  {
    path: '/createProduct',
    name: 'Создание товара',
    component: ProductCreator
  },
  {
    path: '/editProduct/:productID',
    name: 'Редактирование товара',
    component: ProductEditor,
    props: true
  },
  {
    path: '/createNews',
    name: 'Создание новости',
    component: NewsCreator
  },
  {
    path: '/editNews/:newsID',
    name: 'Редактирование новости',
    component: NewsEditor,
    props: true
  },
  {
    path: '/vacancy',
    name: 'Вакансии',
    component: VacancyPage
  },
  {
    path: '/vacancy/:city/:work',
    name: 'Вакансия',
    component: Vacancy,
    props: (route) => ({ city: route.params.city, work: route.params.work, vacancyID: route.query.id })
  },
  {
    path: '/createVacancy',
    name: "Создание вакансии",
    component: VacancyCreator,
  },
  {
    path: '/editVacancy/:vacancyID',
    name: "Редактирование вакансии",
    component: VacancyEditor,
    props: true

  },
  // {
  //   path: '/createWorker',
  //   name: 'Создание сотрудника',
  //   component: WorkerCreator
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    window.location.href = '/404';
  } else {
    next();
  }
});


router.afterEach((to) => {
  if (typeof ym !== 'undefined') {
    ym(98893329, 'hit', to.fullPath);
  }
});


export default router
