<template>
    <div class="OurTeamContainer">
        <Header :style="{position: `relative`}" :theme="$store.state.theme == 'light' ? 'dark' : 'light'"/>

        <div class="MainContainer">
            <div class="subContainer">
                <div class="h2-container">
                    <h1>{{ $store.state.lang == 'ru-RU' ? 'Наша команда' : 'Our team' }}</h1>
                </div>


                <div class="container-body">
                    <div class="CardContainer"

                         v-for="(element, index) in (Team.length > 0 ? Team : 6)"
                    >
                        <div class="delete-button-container" v-if="user == 'admin'"> 
                            <button class="edit-button" @click="editBtn(element)"></button>
                            <button class="delete-button" @click="deleteCommand(element)"></button>
                        </div>
                        <div
                                v-if="this.Team.length === 0"
                                class="sceletonImage"
                        >

                        </div>
                        <div v-else
                             class="image-container"
                             :class="{sceletonImage: this.Team.length === 0}"
                             :style="{backgroundImage: (this.Team.length > 0 ? `url(${element.img})` : undefined)}"


                        ></div>

                        <div class="main-container-text">
                            <p class="text-container"
                                 :class="{sceletonText: this.Team.length === 0}"
                            >
                                {{ $store.state.lang == 'ru-RU' ? element.textRU : element.textEN }}
                            </p>
                            <p class="description-container">
                                {{ element.description }}
                                {{ $store.state.lang == 'ru-RU' ? element.descriptionRU : element.descriptionEN }}
                            </p>
                            <div
                                    v-if="this.Team.length === 0"
                                    class="sceletonText"
                            ></div>
                            <div v-else>
                                <p
                                    class="phone-container"
                                >
                                    {{ element.phone }}
                                </p>
                                <p class="email-container">
                                    {{ element.email }}
                                </p>
                            </div>

                        </div>

                    </div>
                    <div v-if="user == 'admin'" class="add-container" 
                         @click="addBtn()"
                    >
                        <img src="@/assets/icons/addWorker.svg" alt="">
                    </div>
                    
                </div>
                
                <WorkerCreator v-if="exitState == true">
                    <div class="modal-header">
                        <h3>ДОБАВИТЬ НОВОГО СОТРУДНИКА</h3>
                        <img src="@/assets/icons/exit.svg" 
                             alt=""
                             @click="exitBtn"
                             class="exitBtn"
                        >
                    </div>
                    <div class="form-container">
                        <form action="" @submit.prevent="onSubmit">
                        <div class="fio-row">
                            <p class="fio-text">фио</p>
                            <div class="fioInputContainer">
                                <input class="fio-input" type="text" id="fio"
                                    placeholder="Иванов Иван Иванович"
                                    v-model="parseArray[0][1]"
                                    :class="{ emailErr: isFioError && this.parseArray[0][1].length > 0}"
                                >
                                <p class="error-input" :class="{ errorInputOn: isFioError && this.parseArray[0][1].length > 0} ">Введите
                                    полное ФИО</p>
                            </div>

                        </div>

                        <div class="email-row">
                            <p class="email-text">Должность</p>
                            <div class="emailInputContainer">
                                <input class="dol-input"
                                    placeholder="Наименование должности"
                                    v-model="parseArray[1][1]"
                                    @blur="isEmailTouched = true"
                                >
                                
                            </div>

                        </div>
                        <div class="tel-row">
                            <p class="tel-text">Ваш номер телефона</p>
                            <div class="telInputContainer">
                                <input ref="el" class="tel-input"
                                    
                                    @change="telMask($event.target.value)"
                                    :value="parseArray[3][1]"

                                    placeholder="Номер телефона"
                                    :class="{ emailErr: this.parseArray[3][1].length < 18  && this.parseArray[3][1].length > 3} "
                                    
                                >
                                <p class="error-input" 
                                :class="{ errorInputOn: this.parseArray[3][1].length < 18  && this.parseArray[3][1].length > 3} ">
                                    Введите корректый номер телефона
                                </p>
                            </div>

                        </div>

                        <div class="email-row">
                            <p class="email-text">Ваш email адрес</p>
                            <div class="emailInputContainer">
                                <input class="email-input"
                                    :class="{ emailErr: isEmailError && this.parseArray[2][1].length > 3}" type="email"
                                    placeholder="email"
                                    v-model="parseArray[2][1]"
                                    @blur="isEmailTouched = true"
                                >
                                <p class="error-input" :class="{ errorInputOn: isEmailError && this.parseArray[2][1].length > 0} ">Введите
                                    корректый email адрес</p>
                            </div>

                        </div>
                        <div
                        class="image-input-container"
                        @drop="handleDrop"
                        @dragenter="handleDragEnter"
                        @dragleave="handleDragLeave"
                        @dragover.prevent

                        :class="{ 'drag-over': isDragging }"
                        >

                            <div class="input-image-text-container">
                                <div class="input-image-text">Вы можете перетащить или</div>
                                <input type="file" ref="fileInput" multiple @change="handleFileInput" style="display:none;">
                                <button class="input-image-button" @click="triggerFileInput">Открыть на компьютере</button>
                                <div class="input-image-text">Файл(ы) в формате jpg, jpeg, png, webp в эту область.</div>
                                <div class="input-image-text">Максимальный размер файла 10Мб</div>
                            </div>
                            <div class="base64Image-container">
                                <div
                                :style="{backgroundImage: `url(${headerImageBase64})`}"
                                class="base64Image"
                                >

                                </div>
                                <div class="uploaded-images">
                                    <div v-if="this.uploadedNames !== ''">
                                        {{ this.uploadedNames }}
                                        <button class="delete-img-button"
                                                @click="() => {uploadedNames = ''; headerImageBase64 = ''}"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="editStatus == false" class="btn-container">
                            <button @click="public"  class="btn">Coхранить</button>
                        </div>
                        <div v-else class="btn-container">
                            <button @click="edit(element)"  class="btn">Изменить</button>
                        </div>

                        </form>
                    </div>
                  
                    
                </WorkerCreator>
            </div>
        </div>

        <Footer/>
    </div>

</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import WorkerCreator from "@/pages/AdminPages/WorkerCreator.vue";
import {useIMask} from 'vue-imask';

import {HTTP} from "@/plugins/http-common";
import {useHead} from "@vueuse/head";

const emailCheckRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const fioCheckRegex = /^([А-Яа-я]{3,30} [А-Яа-я]{3,30} [А-Яа-я]{3,30})$/i;
const telCheckRegex = /\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/;

export default {
    name: "OurTeam",
    components: {
        Header,
        Footer,
        WorkerCreator
    },
    data() {
        return {
            // viewIndex: 1,
            Team: [],
            imagePath: '',
            user: 'user',
            exitState: false,
            editStatus: false,
            commandID: '',
            
            fio: '',
            tel: '',
            email: '',
            dol: '',
            isEmailTouched: false,
            headerImageBase64: '',
            uploadedNames: '',
            parseArray: [
            
                    ["full_name", ""],
                    ["position", ""],
                    ["email", ""],
                    ["phone", ""],  
                
                        
            ],
            parseArrayEng: 
            [
            
                
                    ["full_name", " "],
                    ["position", " "],
                    ["email", " "],  
                    ["phone", " "]
                
                    
        ],
            top: {}
        }
    },
    setup(props) {
        const {el, masked} = useIMask({
            mask: '+{7} (000) 000 00 00',
            radix: '.',
        });

        return {el};

    },

    methods: {

        exitBtn() {
            this.exitState = false
            this.editStatus = false
            this.parseArray[0][1] = []
            this.parseArray[1][1] = []
            this.parseArray[2][1] = []
            this.parseArray[3][1] = []
            this.headerImageBase64 = ""
            this.uploadedNames = ""
        },

        telMask(value) {
            this.parseArray[3][1] = value
        },

        addBtn() {
            this.exitState = true
            this.parseArray[0][1] = []
            this.parseArray[1][1] = []
            this.parseArray[2][1] = []
            this.parseArray[3][1] = []

            this.headerImageBase64 = ''
            this.commandID = ''

            

        },


        public() {
            if (this.isEmailValid && this.isFioValid) {
                HTTP.post('/command/create_command', {
                    image: JSON.stringify({"image": JSON.parse(JSON.stringify(this.headerImageBase64))}),


                    body: JSON.stringify({
                        "body_ru": JSON.parse(JSON.stringify(this.parseArray)),
                        "body_eng": JSON.parse(JSON.stringify(this.parseArrayEng))
                    }),
                }, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then(response => {
                      this.checkCommand()
                      this.exitBtn()
                    })
            }
     
        },
        deleteCommand(element){
            HTTP.delete('/command/delete_command/' + Number(element.idCommand),
            {headers: {
                'Content-Type': 'application/json'
            }})
            .then(response => {
                this.Team = []
                this.checkCommand()
            }).catch(() => {
                this.wrongData = true;
            })

        },

        edit() {
            const replaceBlobUrls = (item, uploadedNames) => {
                if (item.startsWith('blob:')) {
                  return uploadedNames;
                }
                return item
            };

            const parsedArrayImage = replaceBlobUrls(this.headerImageBase64, this.imagePath);

            HTTP.put('/command/edit_command/' + this.commandID, {
                id: this.commandID,
                image: JSON.stringify({"image": JSON.parse(JSON.stringify(parsedArrayImage))}),
                // category: JSON.stringify({"category_eng": this.categoryEn, "category_ru": this.categoryRu}),
                body: JSON.stringify({
                    "body_ru": JSON.parse(JSON.stringify(this.parseArray)),
                    // ЗАТЫЧКА ДЛЯ АНГЛИЙСКОГО ЯЗЫКА
                    "body_eng": JSON.parse(JSON.stringify(this.parseArrayEng))
                }),
                // description: JSON.stringify({"description": this.pageDescription}),
                // keywords: JSON.stringify({"keywords": JSON.parse(JSON.stringify(this.tagList))}),
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    this.exitBtn()
                    this.checkCommand()



                    })
        },

        editBtn(element){
            this.exitState = true
            this.editStatus = true
            this.parseArray[0][1] = element.textRU
            this.parseArray[1][1] = element.descriptionRU
            this.parseArray[2][1] = element.email
            this.parseArray[3][1] = element.phone 

            this.headerImageBase64 = element.img
            this.commandID = element.idCommand
            console.log(element)
            this.imagePath = element.imagePathArray


        },   

        checkCommand() {
            HTTP.get(`/command/get_all_command`).then(response => {
            console.log(response);
            const teamImages = []
            
            for (let i = 0; i < response.data.data.length; i++) {
              console.log()
                this.Team[i] = {
                    img: JSON.parse(response.data.data[i].image).image,
                    textRU: JSON.parse(response.data.data[i].body).body_ru[0][1],
                    textEN: JSON.parse(response.data.data[i].body)?.body_eng[0][1],
                    descriptionRU: JSON.parse(response.data.data[i].body).body_ru[1][1],
                   
                    email: JSON.parse(response.data.data[i].body).body_ru[2][1],
                    phone: JSON.parse(response.data.data[i].body).body_ru[3][1],
                    idCommand: JSON.parse(response.data.data[i].id),
                    imagePathArray: JSON.parse(response.data.data[i].image).image,

                }

            }

            for (let index = 0; index < response.data.data.length; index++) {
                const imagePath = this.Team[index].img;
                const formData = new FormData();
                formData.append('image', imagePath);
                HTTP.post('/get_image/download_image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob'
                }).then(responseImage => {
                    const url = URL.createObjectURL(responseImage.data);  
                    this.Team[index].img = url
                }).catch(error => {
                    console.error('Ошибка при загрузке изображения:', error);
                });
            }


        }).catch(err => {

            })
        },
        
       
        handleDrop(event) {
            event.preventDefault();
            const files = event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type.startsWith('image/')) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.uploadedNames = file.name
                        this.headerImageBase64 = e.target.result

                    };
                    reader.readAsDataURL(file);
                }
            }
            this.isDragging = false;
        },

        handleDragEnter(event) {
            event.preventDefault();
            this.isDragging = true;
        },

        handleDragLeave(event) {
            event.preventDefault();
            this.isDragging = false;
        },

        triggerFileInput() {
            this.$refs.fileInput.click(); // вызов события click на скрытом инпуте файла
        },

        handleFileInput(event) {
            const files = event.target.files;
            this.uploadFiles(files);
        },

        handleMiniFileInput(event) {
            const files = event.target.files;
            this.uploadMiniFiles(files);
        },

        uploadFiles(files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type.startsWith('image/')) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.uploadedNames = file.name
                        this.headerImageBase64 = e.target.result
                    };
                    reader.readAsDataURL(file);
                }
            }
        },

    },
    
    

    computed: {
        isEmailValid() {
            return emailCheckRegex.test(this.parseArray[2][1]);
        },

        isEmailError() {
            return !this.isEmailValid

        },

        isFioValid() {
            return fioCheckRegex.test(this.parseArray[0][1]);
        },

        isFioError() {
            return !this.isFioValid;

        },

        isTelValid() {
            return telCheckRegex.test(this.parseArray[3][1]);
        },

        isTelError() {
            return !this.isTelValid;

        },

    },

    created() {
        this.checkCommand()

    },

    mounted() {

              
              if(localStorage.user) this.user = localStorage.user;

              useHead({
                title: `Наша команда - ООО «Трансмаш`,
                meta: [
                  {
                    name: `description`,
                    content: `Мы - производитель горно-шахтного оборудования, который предлагает своим клиентам широкий выбор высококачественных товаров.`
                  },
                  {
                    name: `keywords`,
                    content: ``
                  }
                ]

              })

    },  

}
</script>

<style scoped>
.delete-img-button {
    width: 16px;
    height: 16px;

    cursor: pointer;

    background-color: rgb(0, 0, 0, 0);
    border: none;
    outline: none;
    background-image: url('@/assets/icons/exit.svg');
    background-size: cover;

    position: absolute;
    right: 10px;
    top: 12px;
}

.delete-button-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  
  /* z-index: 4 !important; */
  height: 60px;
  column-gap: 20px;
  justify-content: space-between;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom: 410px;
  margin-left: 200px;
}

.delete-button {
  width: 32px;
  height: 32px;

  cursor: pointer;

  background: white;
  font-size: 24px;
  font-family: monospace;

  border-radius: 5px;
  border: solid 1px #ccc;
  background-image: url('@/components/ui/assets/delete.png');
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;

}

.edit-button {
  width: 32px;
  height: 32px;

  cursor: pointer;

  background: white;
  font-size: 24px;
  font-family: monospace;

  border-radius: 5px;
  border: solid 1px #ccc;
  background-image: url('@/components/ui/assets/pencil.png');
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;

}





.input-image-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
    row-gap: 5px;
    
}

.base64Image-container{
    margin-left: -20px;
}

.base64Image {
    width: 220px;
    /*height: 128px;*/
    height: 220px;

    box-sizing: border-box;

    border-radius: 5px;
    background-color: rgb(127, 127, 127);

    /*cursor: pointer;*/

    background-position: center;
    background-size: cover;

    box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);
}
.modal-header{
    width: 100%;
    display: flex;
    justify-content: center;
}

.exitBtn{
    position: absolute;
    margin-left: 95vh;
    margin-top: 25px;
    cursor: pointer;
}

h3{
    font-size: 30px;
    margin-top: 20px;
}

p{
    font-size: 20px;
}

.form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

form {
    display: flex;
    flex-direction: column;
    width: 95%;
    row-gap: 30px;
    padding-left: 40px;
    padding-top: 40px;
    
}

.header-text {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding-bottom: 40px;
}

h2 {
  text-transform: uppercase;
}
h1 {
  font-size: 48px;
  color: var(--text);
  font-family: geometria_extralight;
  text-align: center;
  text-transform: uppercase;
}

.fio-row {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.fioInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    
}

.fio-text {
    text-transform: uppercase;
    padding-right: 20px;
    
}


.fio-input {
    width: 96%;

}

input {
    background-color: #ecf0f1;
    border-radius: 16px;
    width: 100%;
    padding-left: 15px;
    border: none;
    height: 46px;
    box-sizing: border-box;
    font-size: 20px;
}

input:focus {
    border: 2px solid #C9C9C9;
    outline: 0;
    background-color: #FFF;
}

.tel-row {

    display: flex;
    flex-direction: row;
align-items: center;
    justify-content: space-between;

}

.telInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}


.tel-text {
    width: 35%;
}


.tel-input {
    width: 94%;
}


.email-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


}

.emailInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}


.email-input {
    width: 95%;

}

.dol-input{
    width: 92%;
    margin-left: 30px;

}

.emailErr {
    border: 2px solid #fd0000;
}

.error-input {
    padding-top: 5px;
    font-size: 12px;
    line-height: 120%;
    color: #FF0000;
    padding-left: 5px;
    display: none;
}

.errorInputOn {
    display: flex;
}


.message-title {
    border: 2px solid #C9C9C9;
    border-radius: 15px;
    padding: 5px;
}


.message-input:focus {
    border: 2px solid #C9C9C9;
    outline: 0;
}

.btn-container {
    display: flex;
    justify-content: center;
    margin-top: -20px;
}

.btn {
    width: 200px;
    height: 40px;
    border: 2px solid #1D71B8;

    background-color: #FFF;
    border-radius: 15px;
    margin-top: 20px;
    color: #1E70B6;
    font-size: 20px;
    transition: 0.3s;
}

.btn:hover {
    letter-spacing: 2px;

}

.exit-container {
    width: 100%;

    display: flex;

    justify-content: end;
    align-items: flex-start;


}

.exit {
    padding-right: 15px;

    cursor: pointer;
    height: 20px;
    width: 20px;
}

.image-input-container {
    box-sizing: border-box;
    width: 95%;
    height: 310px;
    border-radius: 12px;
    border: dashed 2px #C9C9C9;
    margin-top: 16px;
    padding-top: 20px;

    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    flex-direction: row-reverse;

    /* gap: 30px; */
}

.input-image-text {
    font-size: 20px;
    color: rgb(0, 0, 0, 0.4);
}

.input-image-button {
    background-color: #1D71B8;
    font-size: 20px;
    color: white;

    width: 420px;
    padding: 8px 0px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
}

.text-input-container {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    min-height: 200px;
    border-radius: 12px;
    border: solid 2px #C9C9C9;
    background: white;
    margin-top: 16px;

}

.preview-container {
    width: 100%;
    height: auto;
    margin-top: -180px;

    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;

}

.sub-preview-container {
    width: 100%;
    height: calc(100% - 80px);
    box-shadow: 0px 0px 12px rgb(0, 0, 0, 0.3);
    transform: scale(0.71);

}

.drag-over {
    transition: 0.2s;
    border-width: 3px !important;
    background-color: rgba(0, 166, 255, 0.1);
    border-color: #1D71B8;
}

.uploaded-images {
    width: 100%;
    height: 64px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* background-color: red; */
}

.uploaded-images > div {
    width: 128px;
    height: 32px;

    background: white;
    border-radius: 10px;

    box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
    text-overflow: ellipsis;
    white-space: nowrap;

    align-items: center;
    padding-right: 32px;
    padding-top: 8px;
    padding-left: 8px;
    overflow: hidden;
    position: relative;
}




@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.sceletonImage {
    width: 308px;
    height: 308px;

    overflow: hidden;

    /*position: absolute;*/

    border-radius: 10px;

    top: 16px;
    background: var(--sceleton-card);
    border-radius: 5px;
    background-size: 200% 100% !important;
    animation: 1.5s shine linear infinite;

    box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);


}

.sceletonText {
    background: var(--sceleton-card);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

    box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);
    height: 60px;
    width: 250px;
    border-radius: 15px;


}


.OurTeamContainer {
    position: relative;
    overflow-y: scroll;
    height: 100vh;
    overflow-x: hidden;

}

.MainContainer {
    width: 100vw;
    /*height: 50vh;*/
    padding-top: 55px;
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    color: var(--text);
    background: var(--default-bg);
    scroll-snap-align: center;
    flex-direction: column;
}

.subContainer {
    width: 1080px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* flex-wrap: wrap; */
}

h2 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 48px;
}

.container-body {
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;

    padding: 50px 0;
    display: flex;
    border-radius: 15px;
    /*box-shadow: var(--card-box-shadow);*/
    /*background: var(--card-bg);*/
    /*flex-direction: column;*/
    /* justify-content: center; */
}

.CardContainer {
    width: 336px;
    height: 510px;

    /*position: relative;*/
    display: flex;
    align-items: center;
    justify-content: center;

    /*margin-left: calc(310px/-2);*/

    background: var(--card-bg);
    flex-direction: column;
    border-radius: 12px;

    border-style: solid;
    border-width: 1px;
    border-image: linear-gradient(
            to bottom,
            rgba(20, 20, 20, 0.1),
            #ccc,
            rgba(20, 20, 20, 0.1)
    ) 1 100%;

    box-shadow: var(--card-box-shadow);
    transition: 0.3s;

    row-gap: 20px;
}

.add-container{
    width: 336px;
    height: 510px;

    /*position: relative;*/
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F7F9;
    flex-direction: column;
    /* border-radius: 12px; */
    cursor: pointer;
    
    box-shadow: var(--card-box-shadow);
    transition: 0.3s;
}

.main-container-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
}

.hidden-left {
    transform: translateX(-670px) scale(0.05);
    opacity: 0;
}

.hidden-right {
    transform: translateX(670px) scale(0.05);
    opacity: 0;
}

.left {
    transform: translateX(-370px) scale(0.85);
    opacity: 0.5;
}

.middle {
    transform: translateX(0px) scale(1);
    opacity: 1;
}

.right {
    transform: translateX(370px) scale(0.85);
    opacity: 0.5;
}

.image-container {
    width: 308px;
    height: 308px;

    overflow: hidden;

    /*position: absolute;*/

    border-radius: 10px;

    top: 16px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.image-container img {
    width: 100%;
    height: 100%;
}

.text-container {

    /* height: 130px; */
    bottom: 60px;
    font-size: 25px;
    font-weight: 400;
    /*position: absolute;*/

    display: flex;
    justify-content: center;
    align-items: center;
    width: 304px;
    text-align: center;

    color: var(--text);

}

.description-container {
    width: 100%;
    /* height: 130px; */
    bottom: 40px;


    /*position: absolute;*/

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    color: var(--text);

    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
}

.phone-container {
    width: 100%;
    /* height: 130px; */
    bottom: 25px;

    /*position: absolute;*/

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    color: var(--text);

    font-weight: 400;
    font-size: 16px;
}

.phone-container::before {
    content: '';
    width: 24px;
    height: 12px;
    background-image: url(../../assets/icons/phone.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.email-container {
    width: 100%;
    /* height: 130px; */
    bottom: 10px;

    /*position: absolute;*/

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    color: var(--text);

    font-weight: 400;
    font-size: 16px;
}

.email-container::before {
    content: '';
    width: 24px;
    height: 10px;
    background-image: url(../../assets/icons/message.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media (max-width: 1100px) {
    .subContainer {
        width: 710px;
    }
}

@media (max-width: 750px) {
    .subContainer {
        width: 340px;
    }
}

@media (max-width: 470px) {
    h2 {
        font-size: 5vh;
    }
}

</style>
