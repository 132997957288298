<template>
    <div class="newsIDContainerPage">
        <div >
            <div class="newsIDContainer">
                <div class="newsIDSubContainer">
                    <div class="tite-container">
                        <h2>{{ $store.state.lang == 'ru-RU' ? productName : productNameEng }}</h2>
                    </div>
                    <p class="date">
                        {{
                        "27/06/2023 02:36"
                        }}
                    </p>
                    <div
                            :style="{backgroundImage: `url(${headerImageBase64})`}"
                            class="base64Image"
                            v-if="headerImageBase64"
                    >

                    </div>
                    <div v-else class="container-no-image">
                      <img class="base64Image-img"  src="@/assets/icons/no_image.svg" alt="no image">
                      <p class="no-image-text">Нет фото</p>
                    </div>
                    <div class="parser-container"></div>
                    <Parser :header="parseArray" style="justify-content: start;"/>

                </div>
                
            </div>


            
        </div>

    </div>

</template>

<script>
import {HTTP} from "@/plugins/http-common";
import {useHead} from "@vueuse/head"
import Parser from "@/components/Parser/Parser.vue";


export default {
    name: "NewsCreatePreview",
    components: {Parser},
    props: {
        id : 'id',
        headerImageBase64: '@/assets/icons/no_image.svg',
        productName: '',
        productNameEng: '',
        parseArray: {}

        
    },
    inheritAttrs: false,
    data() {
        return {
            newsTitleRU: [],
            newsParagraphRU: [],
            array_for_parser: [],

            newsTitleEN: [],
            newsParagraphEN: [],

            created_at: [],
            created_ats: [],

            images: [],

            newsOtherTitleRU: [],
            newsOtherTitleEN: [],
            otherNewsID: [],
            imageOtherNews: [],
        }
    },
    methods: {
        scrollToTop() {
        }
    },

    created() {

       
       
        // console.log(this.microNewsTitlesRU)
        // console.log(this.microNewsTitlesEN)
        // console.log(this.images)


    },

    mounted() {
    },

    // setup() {

    //   useHead({
    //       title: `` ,
    //       meta: [
    //         {
    //           name: `description`,
    //           content: `Страница контакты`
    //         },
    //         {
    //           name: `keywords`,
    //           content: `ключевые слова о контактах`
    //         }
    //       ]

    //     })
    //   }

}


</script>

<style scoped>

.parser-container{
    display: flex;
    width: 1000px;
    
    margin-left: -30px;
}
.newsIDContainerPage {
    position: relative;
    overflow-y: scroll;
    color: var(--text);
    overflow-x: hidden;
    height: 100vh;
    /*scroll-snap-type: y proximity;*/
}

.newsIDContainer {
    display: flex;
    color: var(--text);
    padding: 55px 0 60px 0;

    justify-content: center;
    align-items: center;
}

.newsIDSubContainer {
    width: 1000px;
    /*height: 50vh;*/

    display: flex;
    row-gap: 25px;
    justify-content: space-between;
    flex-direction: column;
}

.title-container {
    width: 100%;
}

h2 {
    font-size: 48px;
    font-weight: 500;
}

.date {
    color: #808080;
    font-size: 25px;
    font-weight: 400;
}

.base64Image {
    min-width: 128px;
    /*height: 128px;*/
    height: 600px;

    box-sizing: border-box;

    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    background-color: rgb(127, 127, 127);

    /*cursor: pointer;*/

    background-position: center;
    background-size: cover;

    box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);
}
.container-no-image {
  min-width: 128px;
  /*height: 128px;*/
  height: 600px;

  box-sizing: border-box;

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  background-color: #E9EDF0;
}
.base64Image-img {
  height: 50%;
}
.no-image-text {
  color: #1D71B8;
  font-size: 30px;
}
.paragraph {
    font-size: 25px;
    font-weight: 400;
}

.paragraph:last-child {
    padding-bottom: 55px;
}

.otherNews {

    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 80px;
}

.otherNewsFlexRow {
    display: flex;
    column-gap: 25px;
    row-gap: 35px;
    justify-content: center;
}

.otherNewsImage {
    width: 300px;
    height: 200px;

    box-sizing: border-box;

    border-radius: 5px;
    background-color: rgb(127, 127, 127);

    /*cursor: pointer;*/

    background-position: center;
    background-size: cover;

    box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);
}

.dateOther {
    color: #808080;
    font-weight: 400;
    overflow: hidden;
}

.otherNewsContainer {
    width: 315px;
    display: flex;
    position: relative;
    height: 350px;
    row-gap: 15px;
    flex-direction: column;
}

.linkToOtherNews {
    color: var(--blue);
    overflow: hidden;
    bottom: 0;
}

.otherNewsTitle {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;

}

.paragraph.skeleton {

    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}


@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media (max-width: 1300px) {
    .newsIDSubContainer {
        width: 90%;
    }


    h2 {
        text-align: center;
        font-size: 4.5vh;
    }

    .date {
        font-size: 3vh;
    }

    .base64Image {
        height: 60vh;
    }
}

@media (max-width: 1050px) {
    .otherNewsFlexRow {
        max-width: 1050px;
        flex-wrap: wrap;

    }

    .otherNewsContainer {
        width: 300px;
    }


}

@media (max-width: 644px) {

    .otherNews {
        justify-content: center;
        display: flex;
        align-items: center;

    }

    .otherNewsFlexRow {
        width: 90%;
        flex-direction: column;
        align-items: center;


    }

    .otherNewsTitle {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 50px;

    }

    .date-link-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 40px;
        justify-content: space-between;
        overflow: hidden;
    }

    .dateOther {
        font-size: 14px;

    }

    .otherNewsContainer {
        width: 90%;
        height: 350px;

    }


    .otherNewsImage {
        width: 100%;
        height: 300px;

    }
}

@media (max-width: 550px) {
    .base64Image {
        height: 40vh;
    }

    .otherNewsImage {
        width: 100%;
        height: 40vh;

    }
}
</style>