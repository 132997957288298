import axios from 'axios';

export const HTTP = axios.create({
  baseURL: `${  
                window.location.protocol + '//' +
                window.location.host + '/api' + '/v1'
            }`,
  headers: {
    Authorization: 'Bearer {token}'
  }
})

// export const HTTP = axios.create({
//   baseURL: `${  
//                'http://localhost:44005/api' + '/v1'
//             }`,
//   headers: {
//     Authorization: 'Bearer {token}'
//   }
// })

// export const HTTP = axios.create({
//   baseURL: `http://192.168.1.101:44005/api/v1`,
//   headers: {
//     Authorization: 'Bearer {token}'
//   }
// })