<template>
    <div class="new">
        <div 
            class="base64Image"
            v-if="Base64 && Base64 !== 'No Image'"
        >
            <div class="bluredImage" :style="{backgroundImage: `url(${Base64})`}"></div>
            <img class="normalImage" :src="Base64" alt="image"/>
        </div>
        <div v-else class="container-no-image">
          <img class="base64Image-img"  src="@/assets/icons/no_image.svg" alt="no image">
          <p class="no-image-text">Нет фото</p>
        </div>
        <div class="texts-container">
            <span>{{ FirstPar }}</span>
            <router-link :to="`/news/${name.replaceAll(' ', '_')}?id=` + id" >
              {{$t('Buttons.more')}}
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MicroNews',

        props: {
            Base64: {
                type: String,
                default: () => "No Image"
            },

            FirstPar: {
                type: String,
                default: () => ""
            },
            name: {
                type: String,
                default: () => ""
            },
            id: {
                type: Number,
                default: () => 1
            }
        },

        data(){
            return {

            }
        }
    }
</script>

<style scoped>
    .new {
        width: 50%;
        
        
        box-sizing: border-box;

        display: flex;

    }

    .base64Image {
        min-width: 128px;
        height: 128px;

        box-sizing: border-box;

        border-radius: 12px;
        background-color: rgb(127, 127, 127);

        cursor: pointer;

        background-position: center;
        background-size: cover;

        box-shadow: 1px 2px 12px rgb(0,0,0,0.3);

        overflow: hidden;

        position: relative;
    }

    .container-no-image {
      min-width: 128px;
      height: 128px;

      box-sizing: border-box;

      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      flex-direction: column;
      background-color: #E9EDF0;
    }
    .base64Image-img {
      height: 50%;
    }
    .no-image-text {
      color: #1D71B8;
      font-size: 15px;
    }

    .normalImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: default;
        position: absolute;
    }

    .bluredImage {

        width: 100%;
        height: 100%;

        background-position: center;
        background-size: cover;

        filter: blur(10px);

        position: absolute;
    }

    .texts-container {
        display: flex;
        flex-direction: column;
        

        padding-left: 24px;
        padding-bottom: 36px;
        padding-right: 20px;
    }
    span {
        color: var(--text);
        font-size: 16px;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        overflow: hidden;
    }

    .new a {
        color: var(--blue);
    }

    @media (max-width: 800px){
        .new {
        width: 100%;
        height: 100%;
        margin-top: 20px;
    }

    }
</style>