<template>
    <div>
        <Header :theme="$store.state.theme == 'light' ? 'dark' : 'light'"
                :style="{position: `relative`}"
        />
        <div class="container_not_found_page">
          <div class="sub_container_not_found_page">
            <h2>
              404
            </h2>
            <h1>
              Страница не найдена
            </h1>
            <p>
              Похоже, что-то пошло не так и
            </p>
            <p>этой страницы не существует.</p>
            <button @click="$router.push('/')">
              Вернуться на Главную
            </button>
          </div>
          <img src="@/assets/icons/404.svg" alt="not found image">
        </div>
    </div>


</template>

<script>
import Header from "@/components/Header.vue";
import store from "@/store";

export default {
    name: "NotFound",
    components: {
      Header
    },
    mounted() {
      // store.commit('checkAdminStatus')
    }
}
</script>

<style scoped>
.container_not_found_page {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 50px;
  background: #F5F7F9;
  background-image: url("@/assets/backgrounds/404.svg");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
}
.sub_container_not_found_page {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  color: #1E70B6;
}
h2 {
  font-size: 160px;
  font-weight: bold;
  text-align: center;
}
h1 {
    font-size: 30px;
    text-align: center;
    padding-bottom: 10px;
}
p {
  font-size: 22px;
  text-align: center;
}
button {
  cursor: pointer;
  margin-top: 30px;
  background: #1E70B6;
  font-size: 22px;
  color: #fff;
  border-radius: 15px;
  padding: 15px;
  outline: none;
  border: none;
}
</style>