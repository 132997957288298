<template>
  <div class="crumbs-container">
    <div class="sub-crumb-container">
      <a 
        v-for="(el, index) in path"
        :class="{'active': index < path.length-1, 'current': index == path.length-1}"
        @click="openUrl(el.url)"
      >
        {{ el.name }} 
        <div v-if="index < path.length-1">/</div>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Breadcrumbs",

    data(){
      return {
          
      }
    },

    props: {
      path: {
        type: Array,
        default: [
          {
            name: 'Main',
            url: '/'
          },
          {
            name: 'Catalog',
            url: '/catalog'
          },
          {
            name: 'Category',
            url: '/category'
          },
          {
            name: 'Item',
            url: ''
          },
        ]
      }
    },

    methods: {
      openUrl(url){
        if (url === '') {
          return
        }
        window.open(url, '_self');
        
      }
    },
  }
</script>

<style scoped>
  .crumbs-container {
    position: absolute;

    z-index: 10;
    top: 80px;
    width: 100vw;
    display: flex;

    justify-content: center;
    align-items: center;
  }

  .sub-crumb-container {
    width: 1300px;
    display: flex;
    padding-left: 10px;

  }

  .current {
    color: rgb(189, 189, 189);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    max-width: 600px;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .active {
    color: rgb(138, 138, 138);
    margin-right: 10px;
    cursor: pointer;
    display: flex;

  }

  .active > div {
    margin-left: 10px;
  }

  .active:hover {
    color: rgb(189, 189, 189);

    /* text-decoration: underline; */
  }

  .active:hover > div {
    color: rgb(138, 138, 138) !important;
  }

  @media (max-width: 1300px) {
    .sub-crumb-container {
      width: 100%;
      padding-left: 60px;
    }
  }

  @media (max-width: 700px) {
    .current{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
    }
  }

  @media (max-width: 550px) {
    .current{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }

  @media (max-width: 450px) {
    .current{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
    }
  }
</style>
