<template>
  <div class="container">
    <div class="sub-container" v-for="(element, index, number) in header">
        <h2 v-if="element[0] === 'header'">
          {{element[1]}}
        </h2>

        <p class="paragraph" v-if="element[0] === 'paragraph'">
          {{element[1]}}
        </p>

        <ul v-for="(render) in element[1]" v-if="element[0] === 'list'">
          <li class="list">
            {{render}}
          </li>
        </ul>
        
        <table class="table-container" v-if="element[0] === 'table'"> 
            <tr>
                <th v-for="(columnName) in element[1]"
                    class="column"
                >
                    {{columnName}}
                </th>
            </tr>

            <tr v-for="(rowName, i ) in element.slice(2)"
                class="row"
            >
                <td v-for="row in rowName"
                    class="rowTD"
                >
                    {{row}}
                </td>
            </tr>
        </table>

        <div v-if="element[0] === 'image'"
             class="imageContainer">
            <img :src="element[1]"
                 class="image"
                 v-if="element[1] != []"
            >
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Parser",

  data () {
      return {
          ArrayLength: 2
      }
  },
  props: {
    header: {
      default: []
    },

  },


}
</script>

<style scoped>
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    color: var(--text);
    background: var(--default-bg);
  }
  .sub-container {
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    /*row-gap: 20px;*/
    justify-content: center;
    column-gap: 30px;
    
  }

  .table-container{
    max-width: 1000px;
    width: 100%;
    overflow: scroll !important;
  }

  table {
      color: black;
      overflow: scroll !important;
      font-size: 18px;
  }
  h2 {
    font-weight: 500;
    padding-top: 20px;
    font-size: 25px;
    padding-bottom: 10px;
  }
  .paragraph {
    text-indent: 20px;
    padding-bottom: 10px;
    font-size: 18px;
  }
  .list {
    /*text-indent: 20px*/
    margin-left: 25px;
    font-size: 18px;
    padding-bottom: 10px;
  }
  th {
      font-weight: 300;
      /*font-size: 20px;*/
      background: #DFEFFD;
      text-align: start;
      padding: 10px 5px;

  }
  .row:nth-child(even) {
      background: #FFFFFF;

  }
  .row:nth-child(odd) {
      background: #DFEFFD;
  }
  th:not(:first-child) {
      text-align: center;
  }

  th:last-child {
      text-align: end !important;
  }

  .rowTD:last-child {
      text-align: end !important;
  }

  td {
      padding: 10px 5px;
  }
  .rowTD:not(:first-child) {
      text-align: center;
  }
  .imageContainer {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding-top: 20px;
  }
  .image {
      width: 50%;
      
      overflow: hidden;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
  }

 


  @media (max-width: 1300px){
    .sub-container{
      width: 90%;
    }
  }
</style>
