<template>
  <div class="container">
      <p class="title" style="font-weight: 1000;">
        {{ $t('Catalog.Select_category') }}
      </p>
    <div class="sub-container">
      <div class="category" v-for="(category, index) in categoriesText">
        <router-link
        :to="{
          path: `/catalog${
                method === category.name_eng
                ? ''
                : '/' + category.name_eng
            }`,

          }"
          class="routBtn categoryButton"
          :class="{ buttonClicked: category_type === category.name_eng }"
          @click.native="
            btn(
              method === category.name_eng
                ? (method = '')
                : (method = category.name_eng)
            )
          "
          :style="category_type === category.name_eng ? {color: 'white'} : {color: 'var(--blue)'}"
          style="text-align: center"
        >

          <span class="categoryButtonText" :style = "category_type === category.name_eng ? {color: 'white'} : {color: 'var(--blue)'}">
            {{ $store.state.lang == 'ru-RU' ? category.name_ru.replace('_' , ' ') : category.name_eng.replaceAll('_', ' ')}}
          </span>

        </router-link>

        <div class="buttons-container" v-if="user == 'admin'">
          <button class="button edit" @click="edit(category)"></button>

          <button class="button delete" @click="deleteConfirm(category)"></button>
        </div>
      </div>
      
      <div class="addCategoryButtonContainer" v-if="user == 'admin' && !isCreate">
        <buttom class="addCategoryButton" @click="isOpenAddAndEditCategory = true">
          <img src="./ui/assets/plus.svg" alt="">
        </buttom>
      </div>

    </div>
    <div v-show="isDeleteModal">
        <ModalCofirm 
                :title="`Вы уверены, что хотите удалить категорию ${deleteCategoryObj.name_ru}?`"
                @getClickedClose="isDeleteModal = false"
                @getClickedConfirm="deleteCategory"
        />
    </div>
    <ModalAddAndEditCategory 
      :clicked="isOpenAddAndEditCategory" 
      :getClicked="getClickedParent" 
      :isEdit="isEdit"
      :editCategory="editCategory"
      @categoryUpdated="categoryUpdated" 
      
    />
  </div>
</template>

<script>
import {HTTP} from '@/plugins/http-common'
import ModalCofirm from '../components/ui/ModalCofirm.vue'
import ModalAddAndEditCategory from '../components/ui/ModalAddAndEditCategory.vue'

export default {
  components: {
    ModalCofirm,
    ModalAddAndEditCategory
  },
  emits: [],
  data() {
    return {
      category_type: "",
      user: "user",
      isEdit: false,
      isDeleteModal: false,
      deleteCategoryObj: {},
      isOpenAddAndEditCategory: false,
      editCategory: {}
    };
  },

  methods: {
    btn(method) {
      this.category_type = method;
      this.$emit("category_type_btn", method);
    },
    categoryUpdated() {
      this.$emit('categoryUpdated');
      this.isOpenAddAndEditCategory = false
    },
    edit(category) {
      this.isOpenAddAndEditCategory = true
      this.isEdit = true
      this.editCategory = category
    },
    getClickedParent(clicked) {
      this.isOpenAddAndEditCategory=false,
      this.isEdit = false
      this.editCategory = {}
    },
    updateCategory(category) {
      if (this.editTextRu !== '') {
        this.isEditRuValid = true
      }else{
        this.isEditRuValid = false
      }

      if (this.editTextEng !== '') {
        this.isEditEngValid = true
      }else{
        this.isEditEngValid = false   
      }

      if (this.isEditRuValid && this.isEditEngValid) {
        HTTP.put(`/category/categories/${category.id}`, {
          category_id : category.id,
          name_ru : this.editTextRu,
          name_eng : this.editTextEng,
          
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then((response) => {
          this.isEdit = null;
          this.$emit('categoryUpdated');
        })
      }
      
    },
    updateURL() {
      if (this.$route.path.includes(this.method)) {
        this.$router.replace({ path: '/catalog' });
      }
    },

    createCategory() {
      if (this.createTextRu !== '') {
        this.isCreateRuValid = true
      }else{
        this.isCreateRuValid = false
      }

      if (this.createTextEng !== '') {
        this.isCreateEngValid = true
      }else{
        this.isCreateEngValid = false
        
      }

      if (this.isCreateRuValid && this.isCreateEngValid) {
        HTTP.post(`/category/categories`, {
          name_ru : this.createTextRu,
          name_eng : this.createTextEng,
          
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then((response) => {
          this.isCreate = false;
          this.createTextRu = '';
          this.createTextEng = '';
          this.$emit('categoryUpdated');
        })
      } 
      
    },

    deleteConfirm(category) {
      this.isDeleteModal = true
      this.deleteCategoryObj = category
    },

    deleteCategory() {
      HTTP.delete(`/category/categories/${this.deleteCategoryObj.id}`, {
          
      }, {
          headers: {
              'Content-Type': 'multipart/form-data',
          }
      })
      .then((response) => {
        this.$emit('categoryUpdated');
        this.isDeleteModal = false;
        this.deleteCategoryObj = {};
      })
    },
  },
  props: {
    category_type: {},
    method: {},
    categoriesText: {
      default: [],
    },
  },

  created() {
    this.category_type = '';
  },

  mounted() {
    const currentPath = this.$route.path;
    const categoryMatch = currentPath.match(/\/catalog\/(.+)/);
    this.category_type = categoryMatch ? categoryMatch[1].charAt(0).toLowerCase() + categoryMatch[1].slice(1) : '';

    console.log('Извлечённая категория:', this.categoriesText);

    if (localStorage.user) this.user = localStorage.user;

    if (this.category_type) {
        this.$emit("category_type_btn", this.category_type);
    }
}

  


};
</script>

<style scoped>
.container {
  margin-top: 50px;
  width: 100%;
  margin-bottom: 50px;
  background-color: var(--default-bg);
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title{
  font-size: 48px;
  font-family: geometria_extralight;
  text-transform: uppercase;
  padding-bottom: 4vh;
  color: var(--text)
}

.sub-container {
  width: 90%;
  max-width: 1350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1vh;
  column-gap: 1vw;
}

.category {
  box-sizing: border-box;
  align-self: self-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.category img {
  margin-bottom: 42px;
}

.categoryButton {
  width: 256px;
  height: 48px;
  border-radius: 15px;

  border: solid 2px var(--blue);

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgb(0, 0, 0, 0);
  text-decoration: none;

  cursor: pointer;

}

.categoryButtonText{
  font-family: Geometria_regular;
  font-size: 14px;
  color: var(--blue);
  text-transform: uppercase;
  text-wrap: wrap;
  max-width: 90%;

}

.categoryButton:hover {
  transform: scale(1.02);

}

.buttonClicked {
  background: #1d71b8;
}

.editInput{
  width: 256px;
  height: 48px;
  font-size: 20px;
  border-radius: 15px;

  color: var(--blue);
  border: solid 1px var(--blue);
  text-align: center;

  transition: 0.3s;

}

.addInputsContainer{
  display: flex;
  flex-direction: column;
  row-gap: 0.5vh;
  background-color: var(--default-bg);
 
}

.addInputsSubContainer{
  display: flex;
  flex-direction: row;
  border-radius: 8px;

}

.addInputsSubContainer > span {
  border: solid 1px #AFAFAF;
  border-radius: 8px 0 0 8px;
  width: 15%;
  color: #000000;
  pointer-events: none;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--text);
}

.addInput{
  width: 256px;
  height: 30px;
  font-size: 16px;
  border-radius: 0 8px 8px 0;
  background-color: var(--default-bg);
  color: #000000;
  border: solid 1px #AFAFAF;
  text-align: center;
  color: var(--text);
  transition: 0.3s;

}

.routBtn {
  text-decoration: none;
  cursor: pointer;
}

.buttons-container {

  z-index: 4 !important;
  display: flex;
  flex-direction: column;

  padding: 5px;
  box-sizing: border-box;
  row-gap: 0.5vh;
}

.button {
  width: 32px;
  height: 32px;

  cursor: pointer;

  background: white;
  font-size: 24px;
  font-family: monospace;

  border-radius: 5px;
  border: solid 1px #ccc;

  background-size: 22px;
  background-position: center;
  background-repeat: no-repeat;
}

.button.edit {
  background-image: url("./ui/assets/pencil.png");
}

.button.save {
  background-image: url("./ui/assets/save.svg");
}

.button.delete {
  background-image: url("./ui/assets/delete.png");
}

.addCategoryButtonContainer{
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addCategoryButton{
  width: 3vh;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E9EDF0;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 0.5vw;
}

.addCategoryButton > img{
  width: 70%;
}


@media (max-width: 1100px) {
  .category {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .sub-container {
    flex-wrap: wrap;

  }

  .container {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media (max-width: 550px) {
  .category {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
